<template>
  <el-header class="header">
    <el-row class="h-full content-center " justify="space-between" type="flex">
      <div class="flex items-center header-actions pl-1 pr-1 ">
        <IconLogoFull style="height: 34px; margin-top: 5px; cursor:pointer" @click="$router.push({path:'/'})"/>
        <a href="/storage/seeder_files/about_us.pdf" target="_blank" class="c-deep-sea-800 sm-body ml-2" >{{ $t('about_us') }}</a>
      </div>
      <div class="flex items-center header-actions pl-1 pr-1 f-end">
<!--        <p class="c-deep-sea-800 sm-body mr-1" style="white-space: nowrap">{{$t('document_check')}}</p>-->
<!--        <TheInput :placeholder="$t('enter_document_id')" class="mr-1" v-model.lazy="document_id"/>-->
<!--        <TheButtonPrimary class="mr-2" @click="checkDocumentById">{{$t('check')}}</TheButtonPrimary>-->
        <p class="c-deep-sea-800 sm-body mr-1" style="white-space: nowrap">{{ $t('document_check') }}</p>
        <TheInput v-model.lazy="document_id" :placeholder="$t('enter_document_id')" class="mr-1"
                  @change="checkDocumentById"/>
        <TheButtonPrimary class="mr-2">{{ $t('check') }}</TheButtonPrimary>
        <div
            class="sm-body fw-500"
            style="display: flex; white-space: nowrap;flex-grow: 1;align-items: center"
        >
          {{ $t('tech_support_long') }}:
          <div class="ml-1">
            <a href="tel:+77172972626">{{ $t('tech_support_phone') }}</a><br/>
            <a href="tel:+77713943447">{{ $t('tech_support_mobile_phone') }}</a>
          </div>

        </div>
      </div>
    </el-row>
  </el-header>
</template>

<script setup>
import IconLogoFull from "@/components/Icons/IconLogoFull.vue";
import {ref} from 'vue'
import TheInput from "@/components/TheInput/TheInput.vue";
import TheButtonPrimary from "@/components/TheButton/TheButtonPrimary.vue";
import {useRouter} from "vue-router";
import {ElMessage} from "element-plus";
import {useI18n} from "vue-i18n";
import api from "@/plugins/api";

const document_id = ref()
const router = useRouter();
const { t } = useI18n();
const checkDocumentById = () =>{
  api.documents.index(document_id.value).then(
      res=>{
        router.push({name: 'document-preview',params:{guid: document_id.value, data: res.data}})
      }
  ).catch(()=>{
    ElMessage.error(t('document_not_found'))
  })
}
</script>
