const baseUrl = '/api/auth';
export default ($axios) => {
    return {
        login(form) {
            return $axios.post(`${baseUrl}/login`, form);
        },
        register(form){
            return $axios.post(`${baseUrl}/register`, form);
        }
    };
};
