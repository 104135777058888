const baseUrl = '/api/companies';
export default ($axios) => {
    return{
        companies(){
            return $axios.get(`${baseUrl}`)
        },
        companies_post(){
            return $axios.post(`${baseUrl}`)
        }
    }
}