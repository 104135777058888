<template>
  <component :is="meta?.layout ||'div'" :key="meta?.layout ||'div'">
    <RouterView/>
  </component>
</template>


<script setup>
import {useRoute} from "vue-router";
import {computed} from "vue";

const route = useRoute();
const meta = computed(() => route.meta);
</script>