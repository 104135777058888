import {createRouter, createWebHistory} from 'vue-router';
import MainLayout from '@/layouts/MainLayout.vue'

const router = createRouter({
    history: createWebHistory('/'),
    routes: [
        {
            name:'document-verification',
            path:'/',
            component: ()=>import('/src/pages/documents/DocumentVerification.vue'),
        },
        {
            name:'login',
            path:'/login',
            component: () =>import('/src/pages/auth/TheLogin/TheLogin.vue'),
        },
        {
            name:'register',
            path:'/register',
            component:()=>import('/src/pages/auth/TheRegistration/TheRegistration.vue'),
        },
        {
            name: 'documents-item',
            path: '/documents/:guid',
            props:true,
            component: () => import('/src/pages/documents/DocumentItem.vue'),
            meta: {layout: MainLayout},
        },
        {
            name:'document-preview',
            path:'/documents/preview/:guid',
            component: () => import('/src/pages/documents/DocumentPreview.vue'),
            props: (route) => ({
                ...route.params
            }),
            meta: {layout: MainLayout}
        }
    ]
});

export default router;
