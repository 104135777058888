<template>
  <el-input v-model="localValue" :rows="rows" :type="type">
    <template #append v-if="props.appendIcon">
      <el-button :icon="View" v-if="!passwordShowLocal" @click="changeVisibility(true)"/>
      <el-button :icon="Hide" v-else @click="changeVisibility(false)"/>
    </template>
  </el-input>
</template>
<script setup>
import {computed, defineEmits, defineProps} from "vue";
import {View, Hide} from '@element-plus/icons-vue';
const props = defineProps({
  modelValue: {},
  type: {
    type: String,
    default: 'text'
  },
  rows: {
    type: Number,
    default: 3
  },
  passwordShow:{
    type:Boolean,
    default: false
  },
  appendIcon: {
    type: Boolean,
    default: false
  }
})
const emits = defineEmits(['update:modelValue', 'update:passwordShow'])
const localValue = computed({
  get: () => props.modelValue,
  set: (val) => emits('update:modelValue', val)
})
const passwordShowLocal = computed({
  get:()=>props.passwordShow,
  set:(val)=> {
    emits('update:passwordShow', val)
  }
})
const changeVisibility = (val) =>{
  passwordShowLocal.value = val
}
</script>