import Auth from './api/auth';
import Documents from './api/documents'
import Companies from './api/companies'
import axios from './axios';

const token = localStorage.getItem('token');

if (token) {
    axios.addToken(token);
}

export default {
    auth: Auth(axios),
    documents: Documents(axios),
    companies:Companies(axios)
};
